import { RecommendedMachine } from "ui/src/types";
import * as styles from "./app.module.scss";
import { MachinesList, SortField } from 'ui';

const translationState = window.app.preloadState.translation;

function App() {

    const data: {
        allResults: RecommendedMachine[],
        allResultsHits: number,
        allMachines: number,
        query: string
    } = window.app.preloadState.searchResultModel;

    const pageGet = async (page: number, field: SortField, ascending: boolean) => {
        const response = await fetch(`/api/machinesales/search/all?searchQuery=${data.query}&pageNumber=${page}&pageSize=${data.allResults.length}&sortField=${field}&sortDirection=${ascending ? "asc" : "desc"}`, {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const responseData: RecommendedMachine[] = await response.json();
        return responseData;
    };

    return <div className={styles.wrapper}>
        <div className={styles.searchResultNote}>
            <p>
                {translationState["searchResults.caption"]}
            </p>
        </div>
        <MachinesList initialMachines={data.allResults} total={data.allResultsHits || data.allResults.length} pageCallback={pageGet} />
   </div>
}

export default App;
